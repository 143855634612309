import React from "react";

const Features = () => {
  const data = [
    {
      icon: "https://template65283.motopreview.com/mt-demo/65200/65283/mt-content/uploads/2017/10/mt-1204-home-icons01.png",
      title: "Quick Services",
      content: "Save time and improve efficiency of your business.",
    },
    {
      icon: "https://template65283.motopreview.com/mt-demo/65200/65283/mt-content/uploads/2017/10/mt-1204-home-icons02.png",
      title: "Improved security",
      content: "Additional level of security to keep your information safe.",
    },
    {
      icon: "https://template65283.motopreview.com/mt-demo/65200/65283/mt-content/uploads/2017/10/mt-1204-home-icons03.png",
      title: "Close to you",
      content: "Each of our stores is locally owned and operated.",
    },
    {
      icon: "https://template65283.motopreview.com/mt-demo/65200/65283/mt-content/uploads/2017/10/mt-1204-home-icons04.png",
      title: "Flexible Prices",
      content: "We have flexible pricing policy and a discount system",
    },
    {
      icon: "https://template65283.motopreview.com/mt-demo/65200/65283/mt-content/uploads/2017/10/mt-1204-home-icons05.png",
      title: "Easy Payments",
      content: "You can pay with cash, with a card, or use bank transactions.",
    },
    {
      icon: "https://template65283.motopreview.com/mt-demo/65200/65283/mt-content/uploads/2017/10/mt-1204-home-icons06.png",
      title: "All-in-One",
      content: "We offer scanning, copying, and printing services.",
    },
  ];
  return (
    <div className="flex justify-center py-10" id="features">
      <div className="w-full lg:w-2/3 flex items-center flex-col justify-center">
        <div className="text-3xl font-semibold text-gray-400 p-2">
          FEATURES FOR EVERYONE
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {data.map((item) => (
            <div className="flex flex-col items-center p-4">
              <img src={item.icon} alt="icon" />
              <div className="text-xl">{item.title}</div>
              <div className="text-gray-400">{item.content}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Features;
