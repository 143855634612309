import React from "react";

const Hero = () => {
  return (
    <div
      className="w-screen h-screen relative"
      id="home"
      style={{
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundImage: `url("https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEis3HZ_3yN44WIIvMdczZQQ4f97fxCOy72wteaUH1WDYgrToarxEJTnttp3hS27RIUH-BQO0d2mvNv6nyjKV1jQNMxATchDzhQpyXg4nDH-CMnhLjP5CGZdzWTgun7h0kRFzHcYDLl1jZ-Cik5sQ0hJundMqPp4BYy9jdppbGnOxe6r9-3Fkljaq82bvrI/w640-h512/WWE05374.jpg")`,
      }}
    >
      <div className="absolute top-0 h-screen w-screen bg-black bg-opacity-60 flex flex-col justify-center">
        <div className="text-2xl text-white">Premium Printing Services</div>
        <div
          className="text-3xl lg:text-5xl text-white font-bold
        "
        >
          YOUR ONE-STOP PRINTING SOLUTION
        </div>
        <div className="text-white ">
          We are committed to doing everything we can to help small businesses
          reach their goals
        </div>
        <div className="flex justify-center my-4">
          <button className="bg-orange-500 px-4 py-2 rounded-xl text-white">
            Welcome
          </button>
        </div>
      </div>
    </div>
  );
};

export default Hero;
