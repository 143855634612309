import React from "react";

import { FaFacebook } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { ImTwitter } from "react-icons/im";
import { IoLogoLinkedin } from "react-icons/io5";
import { FaPhoneAlt } from "react-icons/fa";
import { HiLocationMarker } from "react-icons/hi";
import { HiOutlineMail } from "react-icons/hi";

const Footer = () => {
  return (
    <div className="grid grid-col-12 py-10 bg-black bg-opacity-10  ">
      <div className="flex flex-col justify-center  text-gray-900">
        <div className="w-full flex justify-center ">
          <div className="lg:ml-10 px-4 m-0 w-full text-left">
            <div className="mt-10 ">
              <div className="lg:text-lg  mb-5 text-sm text-gray-900 bold  uppercase">
                Keeping in touch is easy, feel free to contact us anytime.
              </div>
              <div className=" mb-5 text-3xl text-gray-500 bold font-semibold uppercase">
                READY TO DISCUSS EVERYTHING IN PERSON OR AT A MEETING
              </div>
              <div className="flex flex-wrap">
                {/* location */}
                <div className="flex items-center mr-4 my-4">
                  <HiLocationMarker size={60} className="text-orange-400" />
                  <div>
                    <div className="font-bold">Address</div>
                    <div>
                      P.O.BOX 10883,
                      <br />
                      Mwenge Milele Street,Dar Es Saalam,{" "}
                    </div>
                  </div>
                </div>
                {/* /location */}
                {/* phone */}
                <div className="flex items-center lg:mx-4 mr-4 my-4">
                  <FaPhoneAlt size={50} className="mx-2 text-orange-400" />
                  <div>
                    <div className="font-bold">Phone</div>
                    <a href="tel:+255713655013">+255 713 655 013</a>
                    <br />
                    <a href="tel:+255716979561">+255 716 979 561</a>
                  </div>
                </div>
                {/* /phone */}
                {/* email */}
                <div className="flex items-center lg:mx-4 mr-4 my-4">
                  <HiOutlineMail size={60} className="mx-2 text-orange-400" />
                  <div>
                    <div className="font-bold">Email</div>
                    <a href="mailto:info@allianceprinters.co.tz ">
                      info@allianceprinters.co.tz
                    </a>
                  </div>
                </div>
                {/* email */}
              </div>
            </div>
          </div>
        </div>

        <div className="w-full  flex justify-center mt-10">
          <div>
            <div className="text-2xl text-orange-400 font-medium">
              Let's Connect
            </div>
            <div className="flex justify-center">
              <a
                href="
                https://www.facebook.com/allianceprinterstz/"
                className="text-lg text-gray-400 mx-2 my-4 hover:text-orange-500"
              >
                <FaFacebook size={30} />
              </a>
              <a
                href="https://www.instagram.com/allianceprinterstz/"
                className="text-lg text-gray-400 mx-2 my-4 hover:text-orange-500"
              >
                <AiFillInstagram size={30} />
              </a>
              <a
                href="https://twitter.com/allianceprinterstz/"
                className="text-lg text-gray-400 mx-2 my-4 hover:text-orange-500"
              >
                <ImTwitter size={30} />
              </a>
              <a
                href="https://www.linkedin.com/allianceprinterstz/"
                className="text-lg text-gray-400 mx-2 my-4 hover:text-orange-500"
              >
                <IoLogoLinkedin size={30} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
