import React from "react";
import { ImFilesEmpty } from "react-icons/im";
import { FaPencilRuler } from "react-icons/fa";
import { IoIosPrint } from "react-icons/io";
import {
  BsFiles,
  BsFillPencilFill,
  BsFillPrinterFill,
  BsTrashFill,
} from "react-icons/bs";
import { IoIosFingerPrint } from "react-icons/io";

export const Services = () => {
  const data = [
    {
      icon: <ImFilesEmpty size={40} className="text-orange-500 mb-5" />,
      title: "Mailing Supplies​​​​​​​",
    },
    {
      icon: <FaPencilRuler size={40} className="text-orange-500 mb-5" />,
      title: "Design Services​​​​​​​",
    },
    {
      icon: <IoIosPrint size={40} className="text-orange-500 mb-5" />,
      title: "Shredding​​​​​​​​​​​​​​",
    },
    {
      icon: <BsFillPrinterFill size={40} className="text-orange-500 mb-5" />,
      title: "Printing Service​​​​​​​​​​​​​​",
    },
    {
      icon: <IoIosFingerPrint size={40} className="text-orange-500 mb-5" />,
      title: "Digital Printing​​​​​​​​​​​​​​",
    },
    {
      icon: <BsTrashFill size={40} className="text-orange-500 mb-5" />,
      title: "Disposal Solution​",
    },
    {
      icon: <BsFiles size={40} className="text-orange-500 mb-5" />,
      title: "Photocopy​",
    },
    {
      icon: <BsFillPencilFill size={40} className="text-orange-500 mb-5" />,
      title: "Stationery Supplies",
    },
  ];
  return (
    <div
      className="flex flex-col items-center py-10 bg-black bg-opacity-5"
      id="services"
    >
      <div className="w-full lg:w-2/3 mb-10 flex items-center flex-col justify-center">
        <div className="text-3xl font-semibold text-gray-400 p-2">
          OUR SERVICES
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2">
          {data.map((item) => (
            <div className="flex rounded-xl flex-col items-center py-10 px-5 bg-white bg-opacity-50 hover:shadow">
              {item.icon}
              <div className="text-xl">{item.title}</div>
            </div>
          ))}
        </div>
      </div>
      {/* second */}
      <div className=" h-screen w-screen  relative regular">
        <div className=" w-full h-full">
          <div
            style={{
              backgroundImage: `url("https://images.pexels.com/photos/4219130/pexels-photo-4219130.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260")`,
              backgroundSize: "cover",
              backgroundPosition: "top",
              height: "100%",
            }}
          />
        </div>
        <div
          className="w-screen h-screen  absolute top-0  flex justify-center items-center"
          style={{
            background: `linear-gradient(to right, black, rgba(0,0,0,.7), rgba(0,0,0,0) )`,
            zIndex: 2,
          }}
        >
          <div className="md:w-3/4 w-full flex flex-wrap justify-between">
            <div className="md:w-2/3 w-full flex justify-center flex-col items-start mx-10 text-left ">
              <div className="lg:text-5xl text-3xl font-bold bold text-white bold text-left ">
                WE OFFER A WIDE RANGE OF SERVICES
              </div>
              <div className="my-2 text-gray-100 ">
                Your business is too important to rely on beginner design.
                Whether you're looking to promote a product or advertise a
                service, we can help you design a layout that will reach your
                audience and get your message across.
              </div>
              <ol>
                <li className="text-white">1. Posters & Envelops</li>
                <li className="text-white">2. Booklets & Books</li>
                <li className="text-white">3. Presentation Folder</li>
                <li className="text-white">4. Business Cards</li>
                <li className="text-white">5. Seminar Workbooks</li>
                <li className="text-white">6. Event Printing</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      {/* second */}
    </div>
  );
};
