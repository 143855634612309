import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
import { CgMenuRightAlt } from "react-icons/cg";

const NavBar = () => {
  const [navScroll, setNavScroll] = useState(true);
  const [expand, setExpand] = useState(false);

  useEffect(() => {
    window.onscroll = () => {
      if (window.scrollY <= 100) {
        setNavScroll(true);
      } else {
        setNavScroll(false);
      }
    };
  }, []);
  return (
    <div
      className={`w-screen fixed top-0 transition-all py-4 py-2 z-10   ${
        !navScroll && "bg-white border border-b"
      }  flex sm:justify-start md:justify-between `}
    >
      <Link
        className=" px-2 text-white object-cover cursor-pointer"
        to="home"
        duration={500}
        smooth={true}
        offset={-70}
        onClick={() => setExpand(false)}
      >
        <img src="./logo.png" alt="logo" className=" h-14 object-cover" />
      </Link>

      {/* opening navigation button */}
      {!expand && (
        <div
          className=" sm:hidden cursor-pointer  absolute right-0 text-orange-500 mx-4"
          onClick={() => {
            setExpand(true);
          }}
        >
          <CgMenuRightAlt size={27} />
        </div>
      )}
      {/* opening navigation button */}

      {/* mobile navigations */}
      <div
        className={
          expand
            ? "flex flex-col transition-all duration-500 sm:hidden w-screen items-start top-0 left-0 right-0 absolute h-screen  p-10 bg-orange-500  text-white"
            : "h-0 overflow-hidden "
        }
      >
        <div
          className="py-2 cursor-pointer "
          onClick={() => {
            setExpand(false);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className={
              expand ? "h-6 w-6 transition-all duration-500 " : "h-6 w-6 "
            }
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>

        <Link
          className="flex text-sm group px-2 flex-col cursor-pointer group hover:text-red-500 hover:"
          to="home"
          duration={500}
          smooth={true}
          offset={-70}
          onClick={() => setExpand(false)}
        >
          <div
            className={
              expand ? "  transition-all duration-500 delay-300" : " flex"
            }
          >
            HOME
          </div>
          <div
            className="bg-gray-200 w-0 transition-all group-hover:w-full"
            style={{
              height: 2,
            }}
          />
        </Link>
        <Link
          className="flex text-sm group px-2 flex-col cursor-pointer group hover:text-red-500 hover:"
          to="features"
          duration={500}
          smooth={true}
          offset={-70}
          onClick={() => setExpand(false)}
        >
          <div
            className={
              expand ? "  transition-all duration-500 delay-500" : " flex"
            }
          >
            FEATURES
          </div>
          <div
            className="bg-gray-200 w-0 transition-all group-hover:w-full"
            style={{
              height: 2,
            }}
          />
        </Link>
        <Link
          className="flex text-sm group px-2 flex-col cursor-pointer group hover:text-red-500 hover:"
          to="services"
          duration={500}
          smooth={true}
          offset={-70}
          onClick={() => setExpand(false)}
        >
          <div
            className={
              expand ? "  transition-all duration-500 delay-700" : " flex"
            }
          >
            SERVICES
          </div>
          <div
            className="bg-gray-200 w-0 transition-all group-hover:w-full"
            style={{
              height: 2,
            }}
          />
        </Link>
      </div>
      {/* mobile navigations */}

      {/* desktop navigations */}
      <div
        className={
          navScroll
            ? " hidden sm:flex  text-white px-2 items-center px-10"
            : " hidden sm:flex  tex-black px-2  items-center px-10"
        }
      >
        <div className="flex items-center ">
          <Link
            className="flex text-sm group px-2 flex-col cursor-pointer group hover:text-red-500 hover:"
            to="home"
            duration={500}
            smooth={true}
            offset={0}
            onClick={() => setExpand(false)}
          >
            <div
              className={
                expand ? "  transition-all duration-500 delay-300" : " flex"
              }
            >
              HOME
            </div>
          </Link>
          <Link
            className="flex text-sm group px-2 flex-col cursor-pointer group hover:text-red-500 hover:"
            to="features"
            duration={500}
            smooth={true}
            offset={-50}
            onClick={() => setExpand(false)}
          >
            <div
              className={
                expand ? "  transition-all duration-500 delay-500" : " flex"
              }
            >
              FEATURES
            </div>
          </Link>
          <Link
            className="flex text-sm group px-2 flex-col cursor-pointer group hover:text-red-500 hover:"
            to="services"
            duration={500}
            smooth={true}
            offset={-50}
            onClick={() => setExpand(false)}
          >
            <div
              className={
                expand ? "  transition-all duration-500 delay-700" : " flex"
              }
            >
              SERVICES
            </div>
          </Link>
        </div>
      </div>
      {/* desktop navigations */}
    </div>
  );
};

export default NavBar;
