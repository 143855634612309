import React from "react";
import Brand from "../component/brands";
import Features from "../component/features";
import Footer from "../component/footer";
import Hero from "../component/hero";
import NavBar from "../component/navbar";
import Price from "../component/price";
import { Services } from "../component/services";
import Team from "../component/team";

const Home = () => {
  return (
    <div
      style={{
        width: "99.0vw",
        overflow: "hidden",
      }}
    >
      <NavBar />
      <Hero />
      <Features />
      <Services />
      {/* <Price /> */}
      <Brand />
      <Footer />
    </div>
  );
};

export default Home;
